@import '../../styles/importer';

$margin: 40px;

.container {
  @include flexcenter-column;

  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  padding: $margin;

  @include mobile {
    padding: ($margin * 0.66669);
  }
}

.previewContainer {
  @include layer;

  position: relative;
  overflow: visible;
}

.containerCarrousel {
  @include flexcenter-column;

  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
}
