@import '../../styles/importer';

$border-normal: 1px solid $border-color;
$border-highlight: 1px solid $secondary-color;
$image-size: 67px;

.filter {
  @include flexcenter-column;
  @include click;

  box-sizing: content-box;
  justify-content: stretch;

  .label {
    width: 100%;
    margin-top: 10px;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
  }

  .image {
    width: $image-size;
    height: $image-size;
    border: $border-normal;
    background-size: cover;
  }

  &.selected {
    .label {
      color: $secondary-color;
      font-weight: 700;
    }

    .image {
      border: $border-highlight;
    }
  }
}
