@import '../../styles/importer';

$padding: 20px;
$padding-mobile: 16px;

.container {
  @include flexcenter-column;

  position: relative;
  width: 100%;
  height: 100%;
  // background-color: blue;
}

.itemLayer {
  @include layer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: $padding;
  padding-top: $brand-height * 2 + $padding;

  @include mobile {
    padding: $padding-mobile;
    padding-top: $brand-mobile-height * 2 + $padding-mobile;
  }

  .label {
    width: 100%;
    color: $background-color;

    h2,
    h3,
    h4 {
      width: 100%;
      text-align: right;
    }
  }
}

.videoContainer {
  @include layer;

  line-height: 0;

  &.hidden {
    display: none;
  }

  video {
    width: 100%;
    height: 100%;
    line-height: 0;
    object-fit: cover;
    object-position: center center;
  }
}

.video {
  display: block;
  width: 100%;
  height: 100%;
}
