@import '../../styles/importer';

.range {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  h3 {
    width: 40px;
    flex-shrink: 0;
    margin-right: 20px;
  }
}

.hack {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.buttons {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 10px;
  @include flex-no-grow;
}

.button {
  @include flexcenter-column;
  @include click;

  width: 40px;
  height: 44px;
  opacity: 0.5;

  h3 {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  &.active {
    color: $secondary-color;
    opacity: 1;

    .icon {
      fill: $secondary-color;
    }
  }

  &.warn {
    color: $warning-color;
    opacity: 1;

    .icon {
      fill: $warning-color;
    }
  }
}
