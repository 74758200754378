@import '../../styles/importer';

.videoPreview {
  padding: 0;
  margin: 0;

  &.landscape {
    height: 100%;
  }

  &.portrait {
    width: 100%;
  }
}
