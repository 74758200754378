@import '../../styles/importer';

.button {
  width: 23px;
  height: 23px;
  margin-left: 20px;
  background-image: url('../../assets/rotate.png');
  background-size: cover;
  @include click;
  @include flex-no-grow;
}
