// Get the utils
@import 'mixins';
@import 'variables';
@import 'functions';
@import 'animations';

/* bring in normalize.css styles */
@import-normalize;

/* Import the fonts */
@import 'typography';

html {
  width: 100vw;
  height: 100%;
  cursor: default; // Cursors only change to hint non-obvious interfaces
}

body {
  overflow: hidden; // No overflow on the app
  width: 100%; // use all the available space
  height: 100%; // use all the available space
  padding: 0; // Documents do not use padding
  margin: 0; // Documents do not use a margin for outer padding
}

*,
::before,
::after {
  box-sizing: border-box; // Box sizing defaults to border-box
  background-repeat: no-repeat; // Backgrounds do not repeat by default
}

// Pseudo-elements inherit text decoration and vertical alignment
::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

// Remove the margin of the header elements
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

// Navigation lists do not include a marker style
nav ol,
nav ul {
  padding: 0;
  list-style: none;
}

// SVGs fallback to the current text color
svg:not([fill]) {
  fill: currentColor;
}

// Tables do not include additional border spacing
table {
  border-collapse: collapse;
}

// Textareas only resize vertically by default
textarea {
  resize: vertical;
}

// Links inherit color and do the cute click
a {
  color: inherit;
  @include click-text;
}

// Single taps are dispatched immediately on clickable elements
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  touch-action: manipulation;
}

// ARIA roles include visual cursor hints
[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled='true'],
[disabled] {
  cursor: default;
}

// Visually hidden content remains accessible
[aria-hidden='false'][hidden] {
  display: initial;
}

[aria-hidden='false'][hidden]:not(:focus) {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

// Form controls appear visually consistent and restyle consistently
button,
input,
select,
textarea {
  padding: 0.25em 0.375em;
  border: 1px solid WindowFrame;
  background-color: transparent;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
}

// Expandable select controls appear visually consistent
select {
  padding-right: 1em;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: no-repeat right center / 1em;
  border-radius: 0;
}

// Remove outlines on focus
a:focus,
select:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

// Buttons have a different cursor
button:hover {
  cursor: pointer;
}

// not selectable have an x next to them
select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E");
}

// Remove expand handle in Internet Explorer
::-ms-expand {
  display: none;
}

// Placeholders appear visually consistent in Internet Explorer
:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $background-color;
}

.application {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  @include mobile {
    flex-direction: column;
  }
}

.loading {
  @include flexcenter-column;

  width: $brand-width;
  height: 100%;

  .brand {
    width: $brand-width;
    height: $brand-height;
    background-image: url('../assets/brand.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .version {
    width: $brand-width;
    margin: 0.5em 0;
    text-align: right;
  }
}

.loader {
  @include flexcenter-column;

  overflow: hidden;
  width: 100%;
  height: 0.3em;
  background-color: $border-color;
  border-radius: 0.3em;

  .progress {
    width: 0%;
    height: 100%;
    align-self: flex-start;
    background-color: $secondary-color;
    transition: width $transition linear;
  }

  .spinner {
    width: 100%;
    height: 100%;
    animation: $transition-long ease-in 0s infinite alternate breathe;
    background-color: $secondary-color;
  }
}
