.container {
  overflow: hidden;
}

.slides {
  display: flex;
  width: 100%;
  max-width: 100vw;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 1px 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}
