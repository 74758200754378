@import '../../styles/importer';

.container {
  @include layer;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(34, 34, 34);

  .message {
    @include layer;

    background-image: url('../../assets/drag-message.png');
    background-position: center;
    background-size: cover;
    transition: opacity 1s ease-out;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }
}
