@import '../../styles/importer';

.container {
  @include content-container;
}

.confirm {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  // margin-top: 20px;
}
