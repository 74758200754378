@import '../../styles/importer';

.modalBackdrop {
  @include flexcenter-column;
  @include layer;

  position: fixed;
  z-index: 20;
  background-color: #fff;
}

.modal {
  position: relative;
  width: calc(90% - 20px);
  height: calc(100% - 20px);
}

.closeIcon {
  position: absolute;
  right: 0;
  width: 24px;

  @include click;

  img {
    svg {
      path {
        fill: $light-grey;
        stroke: $light-grey;
      }
    }
  }
}

.modalContent {
  @include layer;
  @include flexcenter-column;

  padding: 10px;

  img {
    width: 100%;
  }
}
