@import '../../styles/importer';

.buttons {
  display: flex;
  width: 100%;
  height: 32px;
  align-items: stretch;
  justify-content: center;
  border: 1px solid #000;
  @include flex-no-grow;
}

.button {
  width: 100%;
  border-right: 1px solid #000;
  background: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;

  &:last-child {
    border-right: unset;
  }

  &.active {
    background: #2100f5;
    color: #fff;
  }
  @include click;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px 0;
}
